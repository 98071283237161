import VueRouter from 'vue-router'

const routeOptions = [
    {
        path: '/',
        name: 'Home',
    },

    {
        path: '/home',
        name: 'Home',
    },

    {
        path: '/services',
        name: 'Services',
    },

    {
        path: '/about',
        name: 'About',
    },

    {
        path: '/biofuels',
        name: 'Biofuels',
    },
]

const routes = routeOptions.map(route => {
    return {
        ...route,
        component: () => import(`../components/${route.name}.vue`)
    }  
})

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router